<template>
  <loader v-bind="{ loading }" text="Loading Customers">
    <columns>
      <column>
        <page-heading
          heading="Checklist Templates"
          sub-heading="Build and customize checklists that your teams will use to perform work."
        />
      </column>
      <column class="is-narrow">
        <div class="buttons has-addons is-rounded">
          <router-link 
            class="button is-small is-rounded" 
            :to="{
              name: 'checklist-classifications'
            }">
            Classifications
          </router-link>
          <router-link 
            class="button is-small is-rounded" 
            :to="{
              name: 'create-checklist-template'
            }">
            <icon icon="plus"/>
            <span>Template</span>
          </router-link>
        </div>
      </column>
    </columns>
    <columns>
      <column>
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search for checklist templates...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button 
                  @submit="runSearch"
                  class="is-rounded">
                  Search
                </submit-button>
                <action-button
                  class="is-rounded"
                  v-if="isFiltering"
                  @click="clearFilters">
                  <icon icon="times"/> 
                </action-button>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>

    <columns>
      <column>
        <action-button :working="downloading" @click="downloadExcel" left-icon="download" class="is-small is-rounded">
          Excel
        </action-button>
      </column>
    </columns>

    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="checklist in checklists.data" :key="checklist.id">
            <columns>
              <column>
                <router-link class="has-text-weight-bold mr-2" :to="{
                  name: 'checklist-template-manager',
                  params: {
                    checklistTemplate: checklist.uuid
                  }
                }">
                  {{ checklist.name }}
                </router-link>
                <p>
                  <small class="has-text-grey">Last updated {{ checklist.updated_at | asCalendar }}</small>
                </p>
              </column>
              <column class="is-2 is-flex is-align-items-center is-justify-content-end">
                <index-info label="Classification"
                  :info="checklist.classification ? checklist.classification.name : 'Unclassified'" />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <index-info label="Pass Rate" :info="checklist.pass_percentage ? `${checklist.pass_percentage}%` : 'N/A'" />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <index-info icon="list-ol" label="Checkpoints" :info="checklist.checkpoints_count" />
              </column>
            </columns>
          </div>
        </div>
      
        <pager 
          v-if="checklists.data.length" 
          :pageable="checklists" 
          context="Checklists" 
          jump-controls 
          @nav="goToPage"
          class="is-round"
        />

        <no-items-to-display
          :item-count="checklists.data.length"
          with-link
          heading="There are no checklist templates available for display."
          :to="{
            name: 'create-checklist-template'
          }"
          to-text="New Checklist Template"
        />

      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
import { common as backend } from '@/api'
import download from 'downloadjs'

const base_filters = {
  search: ''
}

export default {

  data: () => ({
    loading: true,
    downloading: false,
    checklists: {
      data: []
    },
    filters: {
      search: ''
    }
  }),

  created() {
    if (this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadChecklists()
  },

  methods: {
    loadChecklists() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.checklists = data
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'checklist-templates',
        query: this.filters
      });
    },
    clearFilters() {
      this.filters = { ...base_filters }
      this.$router.push({
        name: 'checklist-templates',
      });
    },
    downloadExcel() {
      this.downloading = true
      backend.downloadFile(`/v1/checklist-templates/download-xls`, ({ data }) => {
        download(data, `checklist-templates.xlsx`, 'xlsx')
        this.downloading = false
      }, () => {
        this.downloading = false
      }, this.filters)
    }
  },

  computed: {
    isFiltering() {
      return Object.values(this.$route.query).length > 0
    }
  },

  watch: {
    '$route': 'loadChecklists'
  }

}
</script>