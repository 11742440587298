var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Customers"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":"Checklist Templates","sub-heading":"Build and customize checklists that your teams will use to perform work."}})],1),_c('column',{staticClass:"is-narrow"},[_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('router-link',{staticClass:"button is-small is-rounded",attrs:{"to":{
            name: 'checklist-classifications'
          }}},[_vm._v(" Classifications ")]),_c('router-link',{staticClass:"button is-small is-rounded",attrs:{"to":{
            name: 'create-checklist-template'
          }}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v("Template")])],1)],1)])],1),_c('columns',[_c('column',[_c('form',[_c('text-input',{attrs:{"classes":"has-addons is-rounded","placeholder":"Search for checklist templates..."},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('submit-button',{staticClass:"is-rounded",on:{"submit":_vm.runSearch}},[_vm._v(" Search ")]),(_vm.isFiltering)?_c('action-button',{staticClass:"is-rounded",on:{"click":_vm.clearFilters}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('columns',[_c('column',[_c('action-button',{staticClass:"is-small is-rounded",attrs:{"working":_vm.downloading,"left-icon":"download"},on:{"click":_vm.downloadExcel}},[_vm._v(" Excel ")])],1)],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.checklists.data),function(checklist){return _c('div',{key:checklist.id,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('router-link',{staticClass:"has-text-weight-bold mr-2",attrs:{"to":{
                name: 'checklist-template-manager',
                params: {
                  checklistTemplate: checklist.uuid
                }
              }}},[_vm._v(" "+_vm._s(checklist.name)+" ")]),_c('p',[_c('small',{staticClass:"has-text-grey"},[_vm._v("Last updated "+_vm._s(_vm._f("asCalendar")(checklist.updated_at)))])])],1),_c('column',{staticClass:"is-2 is-flex is-align-items-center is-justify-content-end"},[_c('index-info',{attrs:{"label":"Classification","info":checklist.classification ? checklist.classification.name : 'Unclassified'}})],1),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-end"},[_c('index-info',{attrs:{"label":"Pass Rate","info":checklist.pass_percentage ? `${checklist.pass_percentage}%` : 'N/A'}})],1),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-end"},[_c('index-info',{attrs:{"icon":"list-ol","label":"Checkpoints","info":checklist.checkpoints_count}})],1)],1)],1)}),0),(_vm.checklists.data.length)?_c('pager',{staticClass:"is-round",attrs:{"pageable":_vm.checklists,"context":"Checklists","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.checklists.data.length,"with-link":"","heading":"There are no checklist templates available for display.","to":{
          name: 'create-checklist-template'
        },"to-text":"New Checklist Template"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }